<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="_rightFields"
      v-model="modelData"
    ></WsReadSection>
    <WsInfoForm
      v-if="modelData&&modelData.tulpa_section"
      class="mt-20"
      :fields="modelData.tulpa_section.fields"
      :value="$_getTulpaSectionContent(modelData.tulpa_section.content,modelData.content)"
    ></WsInfoForm>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_cross_item";
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
export default {
  data() {
    return {
      ...model,
      modelData: null,
      leftFields: ["name", "position"],
    };
  },
  methods: {
    $_getTulpaSectionContent(defaultContent, content) {
      const _content = {};
      for (const key in defaultContent) {
        if (content[key] == null || content.length === 0) {
          _content[key] = defaultContent[key];
        } else {
          _content[key] = content[key];
        }
      }
      return _content;
    },
  },
  computed: {
    _rightFields() {
      const _rightFields = ["tulpa_page_template"];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            _rightFields.push("cmser_groups");
          } else {
            _rightFields.push("admin_groups");
          }
        }
      }
      return _rightFields;
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>